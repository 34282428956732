import { MuiThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  useLocation
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { style } from 'typestyle';
import { Contents } from '../components/Contents';
import { FeatureLists } from '../components/FeatureLists';
import { Header } from '../components/Header';
import MapEditor from '../components/MapEditor';
import { NavigationBar } from '../components/NavigationBar';
import OptionalHeader from '../components/OptionalHeader';
import { Profile } from '../components/Profile';
import { SearchBar } from '../components/SearchBar';
import { SearchList } from '../components/SearchList';
import { UserWorks } from '../components/UserWorks';
import { Work } from '../components/Work';
import { WorkFromQR } from '../components/WorkFromQR';
import { getAuthUser } from '../ducks/auth';
import { PageRouter } from '../pages/PageRouter';
import { SelectorObservableProvider } from '../recoil/selectorObservable';
import theme from '../settings/theme';
import { AgreementsNavBar } from './AgreementsNavBar';
import { AuthUserRedirect } from './AuthUserRedirect';
import { ConfirmManager } from './ConfirmManager';
import { CurrentPageProvider } from './CurrentPageProvider';
import { DocumentTitleProvider } from './DocumentTitleProvider';
import { ExperimentalAvatarChat } from './ai-chat';
import { Fes } from './Fes';
import Footer from './Footer';
import { History } from './History';
import { Landing } from './Landing';
import { LoadingPage } from './LoadingPage';
import { MakeTop } from './MakeTop';
import { NoticeManager } from './NoticeManager';
import {
  Notification,
  NotificationCard,
  SubscribeNotification
} from './Notification';
import { NotificationPopover } from './NotificationPopover';
import { Payment } from './Payment';
import { Plan } from './Plan';
import { ProfileEditDialogProvider } from './ProfileEditDialog';
import { ProfileOfAnonymous } from './ProfileOfAnonymous';
import { Promotion } from './Promotion';
import { Series } from './Series';
import { StaffPicks } from './StaffPicks';
import { SubscribeAuth } from './SubscribeAuth';
import { SuggestLoginDialog } from './SuggestLoginDialog';
import { SuggestVideoCard } from './SuggestVideoCard';
import { TeamLogin } from './TeamLogin';
import { UserPreference } from './UserPreference';
import { VideoList } from './VideoList';
import { WorkRollback } from './WorkRollback';
import { GenerativeAI } from './generative-ai/';

export function App() {
  return (
    <RecoilRoot>
      <SubscribeAuth />
      <SelectorObservableProvider />
      <MuiThemeProvider theme={theme}>
        <Router>
          <React.Suspense fallback={<LoadingPage />}>
            <SubscribeNotification />
            <DocumentTitleProvider />
            <ViewportRoot>
              <Route component={GoogleTagManager} />
              <Route component={RollbarProvider} />
              <Route component={SuggestLoginDialog} />
              <Route component={Header} />
              <Route component={NotificationPopover} />
              <Route component={ConfirmManager} />
              <Route component={NoticeManager} />
              <Route component={CurrentPageProvider} />
              <Route component={ProfileEditDialogProvider} />
              <Route component={ExperimentalAvatarChat} />
              <Route path="/users/:id" component={Profile} />
              <Route
                path="/anonymous/:nickname"
                component={ProfileOfAnonymous}
              />
              <Route path="/anonymous/:query?" component={SearchList} />
              <Switch>
                <Route path="/lists/search/:query" component={SearchBar} />
                <Route path="/lists/:tab?" component={SearchBar} />
                <Route path="/users/:uid/:tab?" component={NavigationBar} />
                <Route component={NavigationBar} />
              </Switch>
              <Route component={OptionalHeader} />
              <Route path="/" exact component={Landing} />
              <Switch>
                <Redirect exact from="/works" to="/lists" />
                <Route path="/lists/search/:query?" component={SearchList} />
                <Route path="/lists/:more?" exact component={FeatureLists} />
              </Switch>
              <Route path="/histories" component={History} />
              <Route
                path="/(works|products|officials)/:id/:action?"
                exact
                component={Work}
              />
              <Route
                path="/rollback/:workId/:versionId"
                component={WorkRollback}
              />
              <Route path="/users/:id/:tab?/:query?" component={UserWorks} />
              <Route path="/plans" component={Plan} />
              <Route path="/payments" component={Payment} />
              <Route path="/notifications" component={Notification} />
              <Route path="/preferences" component={UserPreference} />
              <Route
                path={['/map-editor', '/maps/:id']}
                component={MapEditor}
              />
              <Route path="/qr/:id" component={WorkFromQR} />
              <Route
                path="/pages/agreement/:tab"
                component={AgreementsNavBar}
              />
              <Route path="/fes" component={Fes} />
              <Route
                path="/pages/:contentType?/:slug?"
                component={PageRouter}
              />
              <Route path="/teamLogin" component={TeamLogin} />
              <Route path="/series/:id?/:tab?" component={Series} />
              <Route path="/staffPicks" component={StaffPicks} />
              <Route path="/videos">
                <Redirect to="/videos/season_1" />
              </Route>
              <Route path="/videos/:series" component={VideoList} />
              <Route path="/contents/kit" component={NotificationCard} />
              <Route path="/contents/kit" component={SuggestVideoCard} />
              <Route component={Contents} />
              <Route path="/promotion" component={Promotion} />
              <Route path="/make" component={MakeTop}></Route>
              <Route
                path="/generative-ai/:tab?"
                component={GenerativeAI}
              ></Route>
              <Route path="/" exact component={Footer} />

              <Route path="/authUser(.*)" component={AuthUserRedirect} />
            </ViewportRoot>
          </React.Suspense>
        </Router>
      </MuiThemeProvider>
    </RecoilRoot>
  );
}

const cn = {
  viewportRoot: style({
    position: 'relative',
    height: '100%', // default value
    overflowX: 'hidden', // 横方向に空白ができるのを防ぐ
    display: 'flex',
    flexDirection: 'column'
  })
};

const scrollTops = new Map<string, number>();

// スマホブラウザのバーを除いたウィンドウの高さを要素の高さにする
function ViewportRoot(props: { children: React.ReactNode }) {
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const subscription = fromEvent(window, 'resize')
      .pipe(debounceTime(100)) // must do to iOS chrome https://bit.ly/2QqJ4Td
      .subscribe(() => {
        setHeight(window.innerHeight);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // スクロール位置をページごとに保持する
  const location = useLocation();
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const current = location.key && scrollTops.get(location.key);
    if (current && ref.current) {
      ref.current.scrollTop = current;
    }
    function onScroll() {
      const top = ref.current?.scrollTop || 0;
      scrollTops.set(location.key || '', top);
    }
    const handler = window.setInterval(onScroll, 100);
    return () => {
      window.clearInterval(handler);
    };
  }, [location]);

  return (
    <div className={cn.viewportRoot} style={{ height }} ref={ref}>
      {props.children}
    </div>
  );
}

// Send page view to Google Analytics
function GoogleTagManager({ location }: RouteComponentProps) {
  if (window.gtag) {
    window.gtag('config', process.env.REACT_APP_GA_TRACKING_ID, {
      page_path: location.pathname
    });
  }
  return null;
}

function RollbarProvider() {
  const authUser = useSelector(getAuthUser);

  React.useEffect(() => {
    if (!authUser) return;
    window.Rollbar?.configure({
      payload: { person: { id: authUser.uid, username: authUser.displayName } }
    });
  }, [authUser]);

  return null;
}
