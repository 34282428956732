import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { Done, Send } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';
import { useUpdated } from '../hooks/useUpdated';
import { parentalControlState } from '../recoil';
import firebase from '../settings/firebase';
import { container } from '../utils/xlasses';
import { LoadingButton } from './LoadingButton';
import { LoadingPage } from './LoadingPage';
import { noticeAtom } from './NoticeManager';
import { ParentalControlDialog } from './ParentalControlDialog';
import { PasswordTextField } from './PasswordTextField';
import { NeedSignIn } from './SignInDialog';
import { errorOf } from '../utils/error';
import { I } from './lang';

const auth = firebase.auth;

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '&>div': {
      display: 'block',
      marginBottom: 16,
      padding: 32
    }
  }
}));

export function UserPreference() {
  const cn = useStyles();

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <NeedSignInWrapper>
        <div className={classNames(container.medium, cn.root)}>
          <UpdatePassword />
          <ParentalControl />
        </div>
      </NeedSignInWrapper>
    </React.Suspense>
  );
}

interface NeedSignInWrapperProps {
  children: React.ReactNode;
}

function NeedSignInWrapper(props: NeedSignInWrapperProps) {
  const { initialized, userInfo } = useSelector(state => state.auth);

  return !initialized ? (
    <LoadingPage />
  ) : userInfo ? (
    <>{props.children}</>
  ) : (
    <NeedSignIn />
  );
}

const useStylesUpdatePassword = makeStyles(theme => ({
  button: {
    marginTop: 16
  }
}));

function UpdatePassword() {
  const cn = useStylesUpdatePassword();

  const [current, setCurrent] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const passwordIsTooShort = Boolean(password && password.length < 8); // パスワードが８文字未満である
  const confirmIsNotSame = password !== confirm; // パスワードが一致しない

  const [error, setError] = React.useState<Error>();
  const [loading, setLoading] = React.useState(false);
  const [successed, setSuccessed] = React.useState(false);
  const { initialized, userInfo } = useSelector(state => state.auth);
  const change = React.useCallback(async () => {
    const email = userInfo?.email;
    if (!email) return;
    setError(undefined);
    setLoading(true);

    try {
      const cred = auth.EmailAuthProvider.credential(email, current);
      const uCred = await auth().currentUser?.reauthenticateWithCredential(
        cred
      );
      await uCred?.user?.updatePassword(password);
      setCurrent('');
      setPassword('');
      setConfirm('');
      setSuccessed(true);
    } catch (error) {
      setError(errorOf(error));
    }
    setLoading(false);
  }, [userInfo, current, password]);

  const handleClose = React.useCallback(() => {
    setSuccessed(false);
  }, []);

  const hasPassword = auth().currentUser?.providerData.some(
    info => info?.providerId === 'password'
  );

  return (
    <Paper elevation={0}>
      <Typography variant="h5" gutterBottom>
        <I ja="パスワード変更" en="Change Password" />
      </Typography>
      {error ? (
        <Alert severity="error">
          <I
            ja="パスワードが間違っているようです。先生に相談してください"
            en="Password seems incorrect. Please consult with your teacher."
          />
          <br />
          {error.message}
        </Alert>
      ) : null}
      {!initialized ? (
        <Typography variant="body1" color="textSecondary">
          <I ja="ログイン中です" en="Logging in..." />
        </Typography>
      ) : !userInfo ? (
        <Typography variant="body1" color="textSecondary">
          <I ja="ログインしてください" en="Please log in" />
        </Typography>
      ) : hasPassword ? (
        <>
          <PasswordTextField
            required
            label={<I ja="現在のパスワード" en="Current Password" />}
            value={current}
            onChange={e => setCurrent(e.target.value)}
            helperText={
              <I
                ja="パスワードを変更するために、まずは現在のパスワードを入力してください"
                en="To change your password, please enter your current password first"
              />
            }
          />
          <PasswordTextField
            required
            label={<I ja="新しいパスワード" en="New Password" />}
            value={password}
            onChange={e => setPassword(e.target.value)}
            error={passwordIsTooShort}
            helperText={
              passwordIsTooShort ? (
                <I
                  ja="パスワードは８文字以上にしてください"
                  en="Password must be at least 8 characters"
                />
              ) : (
                <I
                  ja="新しく設定したいパスワードを入力してください"
                  en="Enter the new password you want to set"
                />
              )
            }
          />
          <PasswordTextField
            required
            label={<I ja="パスワードの確認" en="Confirm Password" />}
            value={confirm}
            onChange={e => setConfirm(e.target.value)}
            error={confirmIsNotSame}
            helperText={
              confirmIsNotSame ? (
                <I
                  ja="パスワードが一致していません"
                  en="Passwords do not match"
                />
              ) : (
                <I
                  ja="確認のため、設定したいパスワードをもう一度入力してください"
                  en="Please enter the password again for confirmation"
                />
              )
            }
          />
          <Button
            variant="contained"
            color="primary"
            className={cn.button}
            onClick={change}
            disabled={
              loading || !password || passwordIsTooShort || confirmIsNotSame
            }
          >
            <I ja="パスワードを変更する" en="Change Password" />
          </Button>
        </>
      ) : (
        <Typography variant="body1" color="textSecondary">
          <I
            ja="ここでパスワードを変更することは出来ません。Google アカウントのパスワードは"
            en="You cannot change your password here. For Google account passwords, "
          />
          <Button
            variant="text"
            color="primary"
            target="_blank"
            href="https://myaccount.google.com/signinoptions/password"
            rel="noopener"
          >
            <I ja="こちら" en="click here" />
          </Button>
          <I ja="でご変更ください" en="to change it" />
        </Typography>
      )}
      <Snackbar open={successed} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          <I
            ja="パスワードが変更されました！"
            en="Password has been changed!"
          />
        </Alert>
      </Snackbar>
    </Paper>
  );
}

const useStylesParentalControl = makeStyles(theme => ({
  actions: {
    '&>*': {
      marginRight: 16
    }
  },
  email: {
    padding: '0.25em 1em',
    backgroundColor: 'rgba(41, 121, 255, 0.1)'
  },
  content: {
    marginTop: 32,
    marginBottom: 32
  }
}));

function ParentalControl() {
  const cn = useStylesParentalControl();
  const parentalControl = useRecoilValue(parentalControlState);
  const email = parentalControl?.email; // 現在登録されているメールアドレス. 未登録なら undefined

  const [openDialog, setOpenDialog] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const [mailChanged, setMailChanged] = React.useState(false);

  // メールアドレスが変更されたらフラグを false にする
  useUpdated(() => {
    setMailSent(false);
    setMailChanged(false);
  }, [email]);

  const setNotice = useSetRecoilState(noticeAtom);
  const [loading, setLoading] = React.useState(false);
  const sendMail = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await requestWithAuth(
        endpoint + '/parentalControlEmail',
        'POST'
      );
      if (!result.ok) {
        console.error(result.message);
        setNotice({
          severity: 'error',
          children: (
            <I
              ja="メールの送信中にエラーが発生しました。時間をおいてもう一度お試しください"
              en="An error occurred while sending the email. Please try again later"
            />
          )
        });
        return;
      }
      setMailSent(true);
      setNotice({
        severity: 'info',
        icon: <Send />,
        children: (
          <I
            ja="入力されたアドレスにメールを送信しました。メールをご確認ください"
            en="Email sent to the provided address. Please check your email"
          />
        )
      });
    } catch (error) {
      console.error(error);
      window.Rollbar?.error(error);
      setNotice({
        severity: 'error',
        children: (
          <I ja="メールを送信できませんでした" en="Failed to send email" />
        )
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Paper elevation={0}>
      <Typography variant="h5" gutterBottom>
        <I ja="みまもり設定" en="Parental Controls" />
      </Typography>
      {email ? (
        <>
          <Typography variant="body2">
            <I
              ja="登録されているメールアドレス"
              en="Registered Email Address"
            />
          </Typography>
          <Typography variant="body1" className={cn.email}>
            {email}
          </Typography>
        </>
      ) : null}
      <div className={cn.content}>
        <ParentalControlItem
          value={parentalControl?.enablePublish}
          trueText={
            <I
              ja="ステージの公開が許可されています"
              en="Stage publishing is allowed"
            />
          }
          falseText={
            <I
              ja="ステージを公開できない設定になっています"
              en="Stage publishing is not allowed"
            />
          }
        />
      </div>
      <Grid container className={cn.actions}>
        {mailChanged ? null : (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={email ? sendMail : () => setOpenDialog(true)}
            disabled={mailSent}
            startIcon={mailSent ? <Done /> : undefined}
          >
            {mailSent ? (
              <I ja="メールをご確認ください" en="Please check your email" />
            ) : (
              <I ja="みまもり設定を変更する" en="Change Parental Controls" />
            )}
          </LoadingButton>
        )}
        {email ? (
          <Button
            variant="contained"
            color="default"
            onClick={() => setOpenDialog(true)}
            disabled={mailChanged}
            startIcon={mailChanged ? <Done /> : undefined}
          >
            {mailChanged ? (
              <I ja="メールをご確認ください" en="Please check your email" />
            ) : (
              <I ja="メールアドレスを変更する" en="Change Email Address" />
            )}
          </Button>
        ) : null}
      </Grid>
      <ParentalControlDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onMailAddressSent={
          email ? () => setMailChanged(true) : () => setMailSent(true)
        }
        changeEmail={Boolean(email)}
      />
    </Paper>
  );
}

interface ParentalControlItemProps {
  value?: boolean;
  trueText: React.ReactNode;
  falseText: React.ReactNode;
}

function ParentalControlItem(props: ParentalControlItemProps) {
  return props.value ? (
    <Typography variant="body1" color="textSecondary" gutterBottom>
      {props.trueText}
    </Typography>
  ) : (
    <Typography variant="body1" color="textSecondary" gutterBottom>
      {props.falseText}
    </Typography>
  );
}
