import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { style } from 'typestyle';
import { Link } from '../utils/components';
import { I } from './lang';

const cn = {
  root: style({
    marginTop: 64,
    padding: 64,
    backgroundColor: 'rgb(255,255,255)'
  }),
  button: style({
    display: 'block'
  })
};

const Footer = () => (
  <div className={cn.root}>
    <Grid container>
      <Grid item container direction="column" xs={6}>
        <div>
          <Button component={Link('/pages/post/enterprise')}>
            <I
              ja="法人の方は、法人プランをご利用ください"
              en="For corporate users, please use our business plan"
            />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/terms-of-use')}>
            <I ja="利用規約" en="Terms of Use" />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/terms-of-paid')}>
            <I ja="月額プラン利用規約" en="Monthly Plan Terms" />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/privacy-policy')}>
            <I ja="プライバシーポリシー" en="Privacy Policy" />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/mail-order')}>
            <I
              ja="特定商取引法に基づく表示"
              en="Commercial Transaction Act Information"
            />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/post/brand')}>
            <I ja="ブランドガイドライン" en="Brand Guidelines" />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/agreement/software-license')}>
            <I ja="ライセンス表示" en="License Information" />
          </Button>
        </div>
        <div>
          <Button component={Link('/pages/post/requirements')}>
            <I ja="動作環境" en="System Requirements" />
          </Button>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default Footer;
