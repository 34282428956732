import blue from '@material-ui/core/colors/blue';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: deepOrange,
    secondary: blue,
    text: {
      primary: 'rgba(0, 0, 0, 0.81)'
    }
  },

  typography: {
    fontWeightMedium: 600,
    h2: {
      fontSize: '2rem',
      fontWeight: 600
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 600
    }
  },

  overrides: {
    MuiCardHeader: {
      root: {
        padding: 4
      }
    },
    MuiCardMedia: {
      root: {
        borderRadius: 2
      }
    },
    MuiCardContent: {
      root: {
        padding: 4
      }
    }
  }
});

export default theme;
