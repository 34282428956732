import deepEqual from 'deep-equal';

/**
 * アナリティクス関連の関数をまとめたヘルパー
 * TODO: 上手くトラックできていたらここにまとめる
 */
export const analytics = {
  /**
   * 作品に対してコメント（返信含む）した時のイベント
   */
  commentToWork() {
    gtag('event', 'comment_to_work');
  },
  /**
   * 新しくステージを作って保存した時のイベント
   */
  createWork() {
    gtag('event', 'create_work');
  },
  /**
   * AI解説を見た時のイベント
   */
  explainCode() {
    gtag('event', 'explain_code');
  },
  /**
   * AIエラー解説のを見た時のイベント
   */
  explainError() {
    gtag('event', 'explain_error');
  },
  /**
   * AIストーリー生成を使用した時のイベント
   */
  generateStory(theme: string) {
    gtag('event', 'generate_story', { theme });
  },
  /**
   * AIストーリー生成を使用した時のイベント
   */
  generateStoryIdea(seed: string) {
    gtag('event', 'generate_story_idea', { theme: seed });
  },
  /**
   * 通知ダイアログを開いた
   */
  openNotificationDialog() {
    gtagOnce('event', 'open_notification_dialog');
  },
  /**
   * 授業動画を再生した時のイベント
   * @param title 動画タイトル
   */
  playVideo(title: string) {
    gtagOnce('event', 'Play', {
      event_category: 'Videos',
      event_label: title
    });
  },
  /**
   * ステージを公開した時のイベント
   * （そのステージを初めて公開した時のみ）
   */
  publishWork() {
    gtag('event', 'publish_work');
  },
  /**
   * ステージを検索した時のイベント
   */
  search(query: string) {
    gtagOnce('event', 'search', { search_term: query });
  },
  /** 検索候補をクリックした時のイベント */
  searchCandidate(query: string) {
    gtag('event', 'search_candidate', { query });
  },
  /**
   * 通知の「他 N 人」を表示した（１セッションで複数回検知する）
   */
  showWhoPlay() {
    gtag('event', 'show_who_play');
  },
  /**
   * サブスクリプションを開始した時のイベント（トライアル期間含む）
   */
  startSubscription() {
    gtag('event', 'start_subscription');
    gtag('event', 'conversion', {
      send_to: 'AW-823610472/_FCKCNqku80BEOiY3YgD'
    });
  },
  /**
   * サブスクリプションを中断した時のイベント
   */
  stopSubscription() {
    gtag('event', 'stop_subscription');
  }
};

function gtag(...args: any[]) {
  try {
    window.gtag && window.gtag(...args);
  } catch (error) {
    console.warn(error);
  }
}

const gtagOnceRecords = [] as any[];

function gtagOnce(...args: any[]) {
  if (gtagOnceRecords.some(item => deepEqual(item, args))) return; // 重複を除去
  gtagOnceRecords.push(args);
  gtag(...args);
}
