import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WorkList } from '../components/WorkList';
import { I } from './lang';
import { actions } from '../ducks/actions';
import { fromPaginate } from '../ducks/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing() * 3
    },
    marginBottom: theme.spacing() * 16
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

type Params = {
  more?: string;
};

export function FeatureLists() {
  const cn = useStyles();
  const { more } = useParams<Params>(); // 現在の URL に対して適切なデータを表示
  const userInfo = useSelector(state => state.auth.userInfo);
  const uid = userInfo?.uid;
  const myWorks = useSelector(state =>
    uid ? state.work.users[uid] : undefined
  );

  const trendingIds = useSelector(state => state.work.trendingIds);
  const newer = useSelector(state => state.work.newers);

  const dispatch = useDispatch();
  const fetchNewerMore = React.useCallback(
    () => dispatch(actions.work.fetchNewerMore.started()),
    []
  );
  const canFetchNewer = useSelector(
    state => !state.work.newers.isFetching && state.work.newers.mayHasMore
  );

  React.useEffect(() => {
    dispatch(actions.work.fetchTrendings.started());
  }, []);

  React.useEffect(() => {
    // マウント時点で more があればスクロールする
    if (more) {
      // 何かの要因でスクロールが Top に戻ってしまうので 2000ms 以上のディレイが必要
      window.setTimeout(() => {
        document
          .getElementById(more)
          ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 2000);
    }
  }, []);

  return (
    <Grid container spacing={3} className={cn.root}>
      <Grid item xs={12}>
        <WorkList
          id="trending"
          workIds={trendingIds}
          title={
            <Typography variant="h6" gutterBottom className={cn.title}>
              <img src={require('../resources/diamond_pink.png')} alt="" />
              <I ja="人気のステージ" en="Popular Stages" />
            </Typography>
          }
          more={more === 'trending'}
          moreLink="/lists/trending"
          canFetchMore={more !== 'trending'}
          showVisibility={false}
          getTimestamp={work => work.publishedAt}
        />
      </Grid>
      <Grid item xs={12}>
        <WorkList
          id="recommended"
          workIds={fromPaginate(newer)}
          title={
            <Typography variant="h6" gutterBottom className={cn.title}>
              <img src={require('../resources/diamond_green.png')} alt="" />
              <I ja="最新のステージ" en="Latest Stages" />
            </Typography>
          }
          more={more === 'recommended'}
          moreLink="/lists/recommended"
          onClickMore={fetchNewerMore}
          canFetchMore={canFetchNewer}
          showVisibility={false}
          getTimestamp={work => work.publishedAt}
        />
      </Grid>
      {userInfo ? (
        <Grid item xs={12}>
          <WorkList
            id="mine"
            workIds={fromPaginate(myWorks)}
            title={
              <Typography variant="h6" gutterBottom className={cn.title}>
                <img src={require('../resources/diamond_blue.png')} alt="" />
                <I ja="自分のステージ" en="My Stages" />
              </Typography>
            }
            more={false}
            moreLink="/authUser"
            canFetchMore
            showVisibility
            getTimestamp={work => work.updatedAt}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
