import { makeStyles, PopperProps, Tooltip } from '@material-ui/core';
import { Cancel, MoreHoriz } from '@material-ui/icons';
import * as clipboard from 'clipboard-polyfill';
import { Pos } from 'codemirror';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useConfirm } from '../../../../components/ConfirmManager';
import { Localization } from '../../localization';

export interface SelectionBalloonProviderProps {
  codemirror?: CodeMirror.Editor;
  localization: Localization;
}

type ShowingBalloon = 'copy' | 'paste' | 'none';

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto'
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontSize: '1rem',
    lineHeight: '1.25rem'
  },
  close: {
    marginLeft: theme.spacing() * 2,
    marginBottom: -4,
    cursor: 'pointer',
    height: '1.25rem'
  },
  divider: {
    alignSelf: 'stretch',
    borderLeft: `1px solid ${theme.palette.common.white}`,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: '0.125rem',
    marginBottom: '0.125rem',
    fontSize: '0.75rem'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  moreIcon: {
    marginLeft: theme.spacing() * 2,
    cursor: 'pointer',
    fontSize: '1.25rem'
  }
}));

export function SelectionBalloonProvider(props: SelectionBalloonProviderProps) {
  const cn = useStyles();
  const [copyProps, setCopyProps] = React.useState<Partial<PopperProps>>({});
  const [pasteProps, setPasteProps] = React.useState<Partial<PopperProps>>({});
  const [copied, setCopied] = React.useState<string>();

  const [showing, setShowing] = React.useState<ShowingBalloon>('none');
  const [text, setText] = React.useState(''); // 選択されている文字列
  const [showMore, setShowMore] = React.useState(false);
  React.useEffect(() => {
    const { handler, unmount } = makeHandler(
      setShowing,
      setText,
      setCopyProps,
      setPasteProps,
      setShowMore
    );
    props.codemirror?.on('beforeSelectionChange', handler as any);
    return () => {
      props.codemirror?.off('beforeSelectionChange', handler as any);
      unmount();
    };
  }, [props.codemirror]);

  const handleCopy = React.useCallback(() => {
    clipboard.writeText(text);
    setCopied(text);
  }, [text]);

  const [cut, setCut] = React.useState(false); // コピーではなくカット
  const handleCut = React.useCallback(() => {
    clipboard.writeText(text);
    setCopied(text);
    setCut(true);
    props.codemirror?.replaceSelection('');
  }, [text, props.codemirror]);

  const handlePaste = React.useCallback(() => {
    if (!props.codemirror || !copied) return;
    const cursor = props.codemirror.getCursor();
    props.codemirror.replaceRange(copied, cursor);
    if (cut) {
      handleClear(); // きりとりの場合は一度貼り付けたら自動的に消える
    }
  }, [copied, props.codemirror, cut]);

  const handleClear = React.useCallback(() => {
    setCopied(undefined);
    setShowing('none');
    setCut(false);
  }, []);

  const confirm = useConfirm();
  const handleSend = React.useCallback(async () => {
    handleClear();
    if (
      !(await confirm(
        `このコードを開発者に送りますか？\n${text}`,
        'おくる',
        'やめる'
      ))
    ) {
      return;
    }
  }, [text]);

  return (
    <>
      <Tooltip
        title={
          text === copied ? (
            <div>
              <span className={cn.button}>
                {props.localization.editorCard.copied}
              </span>
            </div>
          ) : (
            <div className={cn.container}>
              <span className={cn.button} onClick={handleCopy}>
                {props.localization.editorCard.copy}
              </span>
              {showMore ? (
                <>
                  <span className={cn.divider} />
                  <span className={cn.button} onClick={handleCut}>
                    {props.localization.editorCard.cut}
                  </span>
                  <span className={cn.divider} />
                  <span className={cn.button} onClick={handleSend}>
                    {props.localization.editorCard.sendCode}
                  </span>
                </>
              ) : (
                <MoreHoriz
                  className={cn.moreIcon}
                  onClick={() => setShowMore(true)}
                />
              )}
            </div>
          )
        }
        open={showing === 'copy'}
        arrow
        placement="top"
        PopperProps={copyProps}
        classes={{ popper: cn.popper }}
      >
        <div>
          {/*Tooltip は chilren を anchorEl にしようとするので、フェイク要素を入れておく*/}
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div>
            <span className={cn.button} onClick={handlePaste}>
              {props.localization.editorCard.paste}
            </span>
            <Cancel className={cn.close} onClick={handleClear} />
          </div>
        }
        open={!!copied && showing === 'paste'}
        arrow
        placement="top"
        PopperProps={pasteProps}
        classes={{ popper: cn.popper }}
      >
        <div></div>
      </Tooltip>
    </>
  );
}

type ChangeObj = {
  origin: '*mouse' | '+move';
  ranges: CodeMirror.Range[];
  update: (ranges: CodeMirror.Range[]) => void;
};

function makeHandler(
  setShowing: React.Dispatch<ShowingBalloon>,
  setText: React.Dispatch<string>,
  setCopyProps: React.Dispatch<Partial<PopperProps>>,
  setPasteProps: React.Dispatch<Partial<PopperProps>>,
  setShowMore: React.Dispatch<boolean>
) {
  // Tooltip の anchor になる要素
  const copyEl = document.createElement('div');
  const pasteEl = document.createElement('div');
  copyEl.style.marginTop = pasteEl.style.marginTop = '-14px'; // Tooltip が文字に被らないようにする

  const handler = (instance: CodeMirror.Editor, changeObj: ChangeObj) => {
    const primary = changeObj.ranges[0];
    const empty = primary?.empty(); // empty であれば選択中ではない
    const showing = empty === undefined ? 'none' : empty ? 'paste' : 'copy';
    setShowing(showing);
    if (showing === 'copy') {
      // 選択されている文字列の中央上に Widget を移動する
      instance.addWidget(center(primary.head, primary.anchor), copyEl, false);
      setCopyProps({ anchorEl: copyEl });
      // 選択中の文字列を取得
      const text = instance.getRange(primary.from(), primary.to());
      setText(text);
    }
    if (showing === 'paste') {
      // カーソルの上に Widget を移動する
      instance.addWidget(primary.head, pasteEl, false);
      setPasteProps({ anchorEl: pasteEl });
    }
    if (showing !== 'copy') {
      setShowMore(false);
    }
  };

  const debounced = debounce(handler, 100);
  return {
    handler: debounced,
    copyEl,
    pasteEl,
    unmount() {
      debounced.cancel();
      copyEl.parentElement?.removeChild(copyEl);
      pasteEl.parentElement?.removeChild(pasteEl);
    }
  };
}

/**
 * a と b の中間地点を求める
 */
function center(a: CodeMirror.Position, b: CodeMirror.Position) {
  const line = Math.min(a.line, b.line);
  const ch = ((a.ch + b.ch) / 2) >> 0;
  return new Pos(line, ch);
}
