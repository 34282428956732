import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { colors } from './colors';

export const defaultPalette = {
  type: 'light',
  primary: random(colors),
  accent: random(colors),
  error: red
};

export default (feelesrc: any) => {
  // Mui Theme (plain object)

  const theme = createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },

    // breakpoints: {},
    // direction: "ltr",
    // mixins: {},
    palette: {
      ...defaultPalette,
      ...feelesrc.palette
    },
    // props: {},
    // shadows: [],
    // typography: {},
    // shape: {},
    // spacing: {},
    transitions: {}
    // zIndex: {},
  });

  if (feelesrc.enableShadow === false) {
    // 影の無効化
    const noShadow = theme.shadows[0];
    for (let index = 0; index < theme.shadows.length; index++) {
      theme.shadows[index] = noShadow;
    }
  }
  if (feelesrc.enableTransition === false) {
    // トランジションの無効化
    theme.transitions.easing.easeInOut = 'none';
    theme.transitions.easing.easeOut = 'none';
    theme.transitions.easing.easeIn = 'none';
    theme.transitions.easing.sharp = 'none';
  }

  return theme;
};

function random<T>(colors: T[]): T {
  const index = (Math.random() * Object.keys(colors).length) >> 0;
  return colors[index];
}
