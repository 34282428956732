import { Grid, Paper, Typography } from '@material-ui/core';
import { Entry } from 'contentful';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { media, style } from 'typestyle';
import { I } from './lang';
import { TopIntro } from './TopIntro';
import { actions } from '../ducks/actions';
import { getContentfulClient } from '../pages/getContentfulClient';
import { Fields, Topic } from '../pages/Topic';
import theme from '../settings/theme';
import { WorkList } from './WorkList';

const cn = {
  root: style({
    maxWidth: 840,
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column'
  }),
  slider: style({
    $nest: {
      '& .slick-prev': {
        left: 25,
        zIndex: 1
      },
      '& .slick-next': {
        right: 25,
        zIndex: 1
      }
    }
  }),
  sliderItem: style(
    {
      position: 'relative',
      height: 480,
      maxHeight: '50vh',
      width: '100%',
      objectFit: 'cover',
      // https://github.com/bfred-it/object-fit-images/
      fontFamily: "'object-fit: contain;'"
    },
    media(
      {
        orientation: 'portrait'
      },
      {
        maxHeight: '30vh',
        opacity: 8 // media query が違うのに 同じ className を出力している
      }
    )
  ),
  workList: style({
    textAlign: 'center'
  }),
  paper: style({
    flex: '0 1 100%',
    padding: 60,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box'
  }),
  title: style({
    display: 'inline-flex',
    alignItems: 'center'
  }),
  flexAlignCenter: style({ display: 'flex', alignItems: 'center' }),
  marginLeft16: style({ marginLeft: 16 }),
  marginTop16: style({ marginTop: 16 }),
  noPadding: style({ padding: 0 }),
  width100: style({ maxWidth: '100%' }),
  makeButton: style({ marginTop: -120, marginBottom: 48 }),
  facebook: style({ border: 'none', overflow: 'hidden' }),
  icon: style({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }),
  body: style({
    alignSelf: 'left',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(4)
  }),
  bottomContainer: style({
    order: 10000
  })
};

export function Landing() {
  const trendingIds = useSelector(state => state.work.trendingIds);
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0); // scroll to top
    dispatch(actions.work.fetchTrendings.started());
  }, []);

  // Contentful の Topic を収集する
  const [topics, setTopics] = React.useState<Entry<Fields>[]>();
  React.useEffect(() => {
    const now = new Date().toISOString();
    getContentfulClient()
      .getEntries<Fields>({
        content_type: 'topic',
        'fields.since[lt]': now,
        'fields.until[gt]': now
      })
      .then(entries => {
        if (entries.errors) {
          console.error(entries.errors);
        } else {
          // Filter out the entry that we've hardcoded
          const filtered = entries.items.filter(item => {
            const title = item.fields.title;
            return (
              title !== 'ハックフォープレイってなに？' &&
              title !== 'はじめてプレイする方はこちら'
            );
          });
          setTopics(filtered);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      <Slider
        dots
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={10000}
        className={cn.slider}
      >
        <img
          src={require('../resources/h4p_top_01.gif')}
          alt={
            navigator.language === 'ja'
              ? 'スライムがたおせない！？これは・・・バグっているね！'
              : "Can't defeat the slime!? Looks like there's a bug!"
          }
          className={cn.sliderItem}
        />
        <img
          src={require('../resources/h4p_top_02.gif')}
          alt={
            navigator.language === 'ja'
              ? '本をひらいてみよう。おかしいのはどこ？'
              : "Let's open the book. Can you find what's wrong?"
          }
          className={cn.sliderItem}
        />
        <img
          src={require('../resources/h4p_top_03.gif')}
          alt={
            navigator.language === 'ja'
              ? 'プログラミングで、せかいをかきかえてみよう！'
              : "Let's rewrite the world with programming!"
          }
          className={cn.sliderItem}
        />
      </Slider>
      <TopIntro />
      <div className={cn.root}>
        {topics
          ? topics.map(entry => <Topic key={entry.sys.id} entry={entry} />)
          : null}

        <Grid container spacing={3} className={cn.bottomContainer}>
          <Grid item xs={12}>
            <WorkList
              id="trending"
              workIds={trendingIds}
              title={
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  className={cn.title}
                >
                  <img src={require('../resources/diamond_pink.png')} alt="" />
                  <I ja="人気のステージ" en="Popular Stages" />
                </Typography>
              }
              more={false}
              moreLink="/lists/trending"
              className={cn.workList}
              showVisibility={false}
              getTimestamp={work => work.publishedAt}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {process.env.NODE_ENV === 'production' ? (
              <iframe
                title="facebook"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhackforplay%2F&tabs=timeline&width=380&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=481203238698048"
                width="408"
                height="500"
                className={cn.facebook}
                scrolling="no"
                frameBorder="0"
                allowTransparency
                allow="encrypted-media"
              />
            ) : null}
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <Paper elevation={1} className={cn.paper}>
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                className={cn.title}
              >
                <img src={info} alt="" />
                ワークショップ情報
              </Typography>
              <img
                src={fest1}
                alt=""
                style={{ width: '100%', maxWidth: 500 }}
              />
              <img
                src={fest2}
                alt=""
                style={{ width: '100%', maxWidth: 500 }}
              />
              <Typography
                variant="body1"
                className={dcn.body}
                gutterBottom
              >
                第６回金沢市キッズプログラミング教室が開催されます
              </Typography>
              <Button
                color="primary"
                variant="contained"
                href="http://www4.city.kanazawa.lg.jp/17009/kidspg.html"
                className={cn.button}
              >
                詳細はこちら
              </Button>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Paper elevation={1} className={cn.paper}>
              <div>
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  className={cn.title}
                >
                  <I ja="運営会社" en="Operating Company" />
                </Typography>
              </div>
              <div>
                <img
                  src={require('../resources/logo.png')}
                  height={60}
                  alt="ハックフォープレイ株式会社"
                />
              </div>
              <Typography variant="body1" gutterBottom>
                <I ja="ハックフォープレイ株式会社" en="HackforPlay Inc." />
              </Typography>
              <div>
                <a href="https://twitter.com/teramotodaiki">
                  <img
                    src={require('../resources/twitter.png')}
                    className={cn.icon}
                    alt="Twitter"
                  />
                </a>
                <a href="https://www.facebook.com/hackforplay">
                  <img
                    src={require('../resources/facebook.png')}
                    className={cn.icon}
                    alt="Facebook"
                  />
                </a>
              </div>
              <Typography variant="body1" gutterBottom>
                <I
                  ja="最新情報は代表の Twitter か公式 Facebook をフォローして下さい"
                  en="Follow our CEO's Twitter or official Facebook page for the latest updates"
                />
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
