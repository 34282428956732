import { Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { classes } from 'typestyle';
import { container, margin, padding } from '../utils/xlasses';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingPage } from './LoadingPage';
import { LoadingButton } from './LoadingButton';
import { requestWithAuth } from '../ducks/requestWithAuth';
import { endpoint } from '../env';

interface CustomerPortalLinkProps {
  children: React.ReactNode;
}

/** StripeのCustomer Portalを開くリンク。一時的なURLをfetchで取得し、そのURLに遷移する */
function CustomerPortalLink(props: CustomerPortalLinkProps) {
  const [loading, setLoading] = React.useState(false);

  /**
   * StripeのCustomer Portalを開く。URLはAPIで毎回生成する
   */
  const openCustomerPortal = async () => {
    setLoading(true); // ページ遷移するので、ローディング状態はこのままにする
    try {
      const { url } = await requestWithAuth(endpoint + '/stripeCustomerPortal');
      location.href = url;
    } catch (error) {
      console.error(error);
      alert('エラーが発生しました。しばらくしてからもう一度お試しください。');
    }
  };

  return (
    <LoadingButton
      loading={loading}
      onClick={openCustomerPortal}
      variant="contained"
      color="primary"
    >
      {props.children}
    </LoadingButton>
  );
}

export function Payment() {
  return (
    <Paper
      elevation={2}
      className={classes(margin.large, padding.large, container.medium)}
    >
      <ErrorBoundary>
        <Typography variant="h4" gutterBottom>
          お支払い情報
        </Typography>
        <React.Suspense fallback={<LoadingPage />}>
          <CustomerPortalLink>お支払い情報を確認</CustomerPortalLink>
        </React.Suspense>
      </ErrorBoundary>
    </Paper>
  );
}
