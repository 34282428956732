import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../ducks/actions';
import { I } from './lang';
import { getAuthUser } from '../ducks/auth';
import { objectFit } from '../utils/xlasses';
import { DialogClose } from './DialogClose';
import { EditableTextField } from './EditableTextField';
import { LoadingPage } from './LoadingPage';

enum Phase {
  ListTemplates = 1,
  ListIcons,
  ListColors,
  EditName
}

const skinEndpoint = 'https://skins.hackforplay.xyz/';
const getSkinIconSrc = (name: string) => `${skinEndpoint}_icons/${name}.png`;
const templates = [
  ['samurai', '#FBC02D'],
  ['hunter', '#F57C00'],
  ['merchant', '#9C27B0'],
  ['blue_slime', '#00ACC1'],
  ['red_penguin_knight', '#E53935'],
  ['blue_witch_cat', '#1E88E5'],
  ['white_skeleton', '#43A047'],
  ['black_vampire', '#37474F']
];
const defaultValue = templates[(Math.random() * templates.length) >> 0];
const colors = [
  '#E53935',
  '#9C27B0',
  '#3949AB',
  '#1E88E5',
  '#00ACC1',
  '#43A047',
  '#C0CA33',
  '#FBC02D',
  '#F57C00',
  '#795548',
  '#607D8B',
  '#37474F'
];

const useStyles = makeStyles(theme => ({
  header: {
    paddingRight: theme.spacing() * 2
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    maxHeight: 400
  },
  circle: {
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '50%',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(),
    '& > img': {
      width: '100%',
      height: '100%'
    }
  },
  iconContainer: {
    backgroundColor: '#EDF0F3'
  },
  iconItem: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  selected: {
    margin: -2,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 4
  },
  colorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -theme.spacing() / 2,
    '& > div': {
      margin: theme.spacing() / 2
    },
    '& > div > div': {
      cursor: 'pointer',
      width: 64,
      height: 64
    }
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'sticky',
    top: 0
  },
  fileInput: {
    display: 'none'
  }
}));

export interface ProfileEditDialogProps extends DialogProps {
  defaultPhase?: Phase;
}

export function ProfileEditDialog(props: ProfileEditDialogProps) {
  const cn = useStyles();

  const authUser = useSelector(getAuthUser);

  const [src, setSrc] = React.useState(getSkinIconSrc(defaultValue[0]));
  const [blob, setBlob] = React.useState<Blob>();
  const [blobUrl, setBlobUrl] = React.useState('');
  const [color, setColor] = React.useState(defaultValue[1]);
  const [displayName, setDisplayName] = React.useState(authUser?.displayName);
  React.useEffect(() => {
    if (!authUser) return;
    if (authUser.iconUrl) {
      setSrc(authUser.iconUrl);
    }
    if (authUser.profile?.color) {
      setColor(authUser.profile.color);
    }
    if (authUser.displayName) {
      setDisplayName(authUser.displayName);
    }
  }, [authUser]);

  const [phase, setPhase] = React.useState(
    props.defaultPhase ?? Phase.ListTemplates
  ); // ListIcons になった時スクロールして見せる
  const goFoward = React.useCallback(() => {
    setPhase(Math.min(phase + 1, Phase.EditName));
  }, [phase]);
  const goBack = React.useCallback(() => {
    setPhase(
      phase === Phase.EditName
        ? Phase.ListTemplates
        : Math.max(phase - 1, Phase.ListTemplates)
    );
  }, [phase]);

  const handleSelectTemplate = (name: string, color: string) => () => {
    setSrc(getSkinIconSrc(name));
    setColor(color);
    setBlob(undefined);
    setBlobUrl('');
    setPhase(Phase.EditName);
  };

  const handleSelectIcon = (name: string) => () => {
    setSrc(getSkinIconSrc(name));
    setBlob(undefined);
    setBlobUrl('');
    setPhase(Phase.ListColors);
  };

  const skinNames = useSelector(state => state.asset.skinNames);

  // 選択中のアイテムがある場所までスクロールする
  const selectedRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // スクロールできるよう render をまつ
    setTimeout(() => {
      selectedRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }, 500);
  }, [phase]);

  const handleClose = React.useCallback(() => {
    props.onClose?.({}, 'escapeKeyDown');
  }, [props.onClose]);

  const fileRef = React.useRef<HTMLInputElement>(null);
  const handleFileOpen = React.useCallback(() => {
    fileRef.current?.click();
  }, []);
  const handleFileSet = React.useCallback(() => {
    const file = fileRef.current?.files?.[0];
    if (file) {
      setBlob(file);
      setBlobUrl(URL.createObjectURL(file));
      setSrc(''); // blob をセットした場合は src を消す
    }
  }, [blobUrl]);
  // blobUrl が不要になったタイミングで revoke する
  React.useEffect(
    () => () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    },
    [blobUrl]
  );

  const dispatch = useDispatch();
  const handleUpdate = React.useCallback(() => {
    if (!authUser) return;
    dispatch(
      actions.user.updateProfile.started({
        uid: authUser.uid,
        blob,
        src,
        color,
        displayName: displayName || 'guest'
      })
    );
    handleClose();
  }, [authUser, blob, src, color, displayName, handleClose]);

  if (!authUser) {
    // ユーザー情報が取得できていない間は何も表示しない
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <LoadingPage />
      </Dialog>
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogClose onClick={handleClose} />
      <DialogTitle disableTypography>
        <Typography variant="subtitle1" className={cn.header}>
          <I
            ja={
              phase === Phase.ListTemplates
                ? 'きみはどのアイコンにする？'
                : phase === Phase.ListIcons
                ? 'どのキャラクターにする？'
                : phase === Phase.ListColors
                ? 'はいけいはどの色にする？'
                : 'ニックネーム（あだな）をおしえてね'
            }
            en={
              phase === Phase.ListTemplates
                ? 'Choose your icon'
                : phase === Phase.ListIcons
                ? 'Choose your character'
                : phase === Phase.ListColors
                ? 'Choose background color'
                : 'Enter your nickname'
            }
          />
        </Typography>
        <Typography variant="body1" color="textSecondary">
          <I ja="いつでも かえられるよ" en="You can change this anytime" />
        </Typography>
      </DialogTitle>
      <DialogContent className={cn.container}>
        {phase === Phase.ListTemplates ? (
          <>
            {templates.map(([name, color]) => (
              <Grid key={name + color} item sm={3} xs={4}>
                <div
                  className={cn.circle}
                  style={{ backgroundColor: color }}
                  onClick={handleSelectTemplate(name, color)}
                >
                  <img src={getSkinIconSrc(name)} alt={name} />
                </div>
              </Grid>
            ))}
          </>
        ) : phase === Phase.ListIcons ? (
          skinNames ? (
            <>
              <Grid item xs={6} sm={8}>
                <Grid container className={cn.iconContainer}>
                  {Object.values(skinNames).map(names => (
                    <Grid
                      key={names.en}
                      item
                      xs={6}
                      sm={3}
                      ref={
                        src === getSkinIconSrc(names.en)
                          ? selectedRef
                          : undefined
                      }
                    >
                      <img
                        className={classNames(
                          cn.iconItem,
                          src === getSkinIconSrc(names.en) && cn.selected
                        )}
                        src={getSkinIconSrc(names.en)}
                        alt={names.ja}
                        onClick={handleSelectIcon(names.en)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4} className={cn.previewContainer}>
                <div
                  className={classNames(cn.circle)}
                  style={{ backgroundColor: color }}
                >
                  <img
                    src={blobUrl || src}
                    alt={src}
                    className={objectFit.cover}
                  />
                </div>
              </Grid>
            </>
          ) : (
            <LoadingPage />
          )
        ) : phase === Phase.ListColors ? (
          <>
            <Grid item xs={8} sm={8}>
              <div className={cn.colorsContainer}>
                {colors.map(c => (
                  <div key={c}>
                    <div
                      className={classNames(color === c && cn.selected)}
                      style={{ backgroundColor: c }}
                      onClick={() => setColor(c)}
                      ref={color === c ? selectedRef : undefined}
                    />
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} className={cn.previewContainer}>
              <div
                className={classNames(cn.circle)}
                style={{ backgroundColor: color }}
              >
                <img
                  src={blobUrl || src}
                  alt={src}
                  className={objectFit.cover}
                />
              </div>
            </Grid>
          </>
        ) : phase === Phase.EditName ? (
          <>
            <Grid item xs={12} sm={4} className={cn.previewContainer}>
              <div
                className={classNames(cn.circle)}
                style={{ backgroundColor: color }}
              >
                <img
                  src={blobUrl || src}
                  alt={src}
                  className={objectFit.cover}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={8} className={cn.previewContainer}>
              <EditableTextField
                autoFocus
                id={authUser?.uid}
                defaultValue={authUser?.displayName}
                onEdit={setDisplayName}
                helperText={
                  <I
                    ja="本名（ほんみょう）は ダメです"
                    en="Please do not use your real name"
                  />
                }
              />
            </Grid>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        {phase === Phase.ListTemplates ? (
          <>
            {authUser?.iconUrl ? (
              <Button
                variant="outlined"
                onClick={() => setPhase(Phase.EditName)}
              >
                <I ja="今のままでいい" en="Keep current" />
              </Button>
            ) : null}
            <Button variant="contained" color="primary" onClick={goFoward}>
              <I ja="じぶんでアイコンをつくる" en="Create your own icon" />
            </Button>
          </>
        ) : null}
        <input
          type="file"
          accept="image/*"
          ref={fileRef}
          className={cn.fileInput}
          onChange={handleFileSet}
        />
        {phase === Phase.ListIcons ? (
          <Button variant="text" color="primary" onClick={handleFileOpen}>
            <I ja="画像をアップロード" en="Upload image" />
          </Button>
        ) : null}
        {phase !== Phase.ListTemplates ? (
          <Button variant="outlined" color="primary" onClick={goBack}>
            <I ja="もどる" en="Back" />
          </Button>
        ) : null}
        {phase === Phase.ListIcons || phase === Phase.ListColors ? (
          <Button variant="contained" color="primary" onClick={goFoward}>
            <I ja="つぎへ" en="Next" />
          </Button>
        ) : null}
        {phase === Phase.EditName ? (
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            <I ja="けってい" en="Confirm" />
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

/**
 * "color" がセットされていないユーザーがログインしたときに
 * 自動的に表示するための Provider
 */
export function ProfileEditDialogProvider() {
  const authUser = useSelector(getAuthUser);

  // profile がセットされていない場合は自動的に表示する
  const [defaultPhase, setDefaultPhase] = React.useState<Phase>();
  const [closed, setClosed] = React.useState(false);
  React.useEffect(() => {
    if (authUser && authUser.profile === undefined) {
      // アイコンがすでに設定されていればアイコンの設定をスキップ
      setDefaultPhase(Phase.ListTemplates);
    } else if (authUser && authUser.displayName === undefined) {
      setDefaultPhase(Phase.EditName);
    }
  }, [authUser]);

  return defaultPhase !== undefined ? (
    <ProfileEditDialog
      open={!closed}
      defaultPhase={defaultPhase}
      onClose={() => setClosed(true)}
    />
  ) : null;
}
