import { INLINES } from '@contentful/rich-text-types';
import { Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import { style } from 'typestyle';

import { HyperLink } from '../pages/HyperLink';
import { EN, JA } from './lang';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    '& h1,& h2,& h3,& h4,& h5,& h6': {
      display: 'inline-flex',
      alignItems: 'center',
      marginTop: 0
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  heading: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 14
  },
  paragraph: {
    minHeight: '1em',
    marginTop: 8
  }
}));

const cn = {
  container: style({
    margin: '2rem auto',
    maxWidth: 840,
    textAlign: 'center'
  })
};

export function TopIntro() {
  const classes = useStyles();

  return (
    <div className={cn.container}>
      <Paper elevation={2} className={classes.root}>
        <img
          src="https://i.gyazo.com/33f6a27a77a12499bc4a96e2a2a9d761.png"
          alt="初心者マークつきのまほうつかい"
        />

        <p className={classes.paragraph}>
          <JA>ハックフォープレイってなに？</JA>
          <EN>What is Hackforplay?</EN>
        </p>

        <div className={classes.heading}>
          <h2>
            <JA>はじめてプレイする方はこちら</JA>
            <EN>If you are new here, please start here</EN>
          </h2>
        </div>

        <div className={classes.heading}>
          <h2>
            <HyperLink
              variant="contained"
              node={{
                nodeType: INLINES.HYPERLINK,
                data: {
                  uri: 'https://www.hackforplay.xyz/works/E0TFzuJeGoO75Fp1QNU0'
                },
                content: []
              }}
            >
              <JA>ゲームスタート</JA>
              <EN>Start Game</EN>
            </HyperLink>
          </h2>
        </div>
      </Paper>
    </div>
  );
}
