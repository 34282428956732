import {
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../settings/firebase';
import { container } from '../utils/xlasses';
import { PasswordTextField } from './PasswordTextField';
import { I } from './lang';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 32,
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: 32
    }
  }
}));

export function TeamLogin() {
  const classes = useStyles();

  // 入力内容
  const [teamId, setTeamId] = React.useState('');
  const [loginId, setLoginId] = React.useState('');
  const [password, setPassword] = React.useState('');

  // ログイン
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const history = useHistory();
  const signIn = React.useCallback(() => {
    const email = anonymousEmail(teamId, loginId);
    setError(undefined);
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        if (user) {
          history.push(`/`);
        } else {
          setError(
            new Error('ログインに失敗しました。入力内容をお確かめください')
          );
        }
      })
      .catch(setError)
      .then(() => {
        setLoading(false);
      });
  }, [teamId, loginId, password]);

  return (
    <Paper
      elevation={2}
      className={classNames(container.medium, classes.container)}
    >
      <Typography variant="h5">
        <I ja="チームログイン" en="Team Login" />
      </Typography>
      {error ? (
        <Alert severity="error">
          <I
            ja={error?.message}
            en={
              error?.message ===
              'ログインに失敗しました。入力内容をお確かめください'
                ? 'Login failed. Please check your input.'
                : error?.message
            }
          />
        </Alert>
      ) : null}
      <Alert severity="info">
        <I
          ja="こちらは教室として契約している方専用のログイン画面です。一般の方は右上のログインボタンを押してください"
          en="This login screen is for classroom contract users only. For general users, please use the login button in the top right."
        />
      </Alert>
      <TextField
        label={<I ja="チーム" en="Team" />}
        variant="outlined"
        helperText={
          <I
            ja="分からない場合は教室で先生に聞いてね"
            en="If you're not sure, please ask your teacher in the classroom"
          />
        }
        value={teamId}
        onChange={e => setTeamId(e.target.value)}
        required
      />
      <TextField
        label={<I ja="ログイン ID" en="Login ID" />}
        variant="outlined"
        helperText={
          <I
            ja="ログイン ID は２文字以上の英数字または _ です"
            en="Login ID must be at least 2 alphanumeric characters or _"
          />
        }
        value={loginId}
        onChange={e => setLoginId(e.target.value)}
        required
      />
      <PasswordTextField
        variant="outlined"
        required
        label={<I ja="パスワード" en="Password" />}
        value={password}
        error={Boolean(password && password.length < 8)}
        onChange={e => setPassword(e.target.value)}
        helperText={
          <I
            ja="パスワードは８文字の文字列です"
            en="Password must be 8 characters long"
          />
        }
      />
      <Button
        variant="contained"
        color="primary"
        onClick={signIn}
        disabled={loading}
      >
        <I ja="ログイン" en="Login" />
      </Button>
    </Paper>
  );
}

/**
 * チームメンバーは Email を持っていないので、仮のアドレスらしきものを生成して一意にする
 * 実在するメールアドレスと衝突してはいけない
 * メールアドレスの形式が正しくないと、 Error: The email address is improperly formatted. エラーがスローされる
 * @param teamLoginId チームごとに割り当てられている一意な文字列
 * @param loginId メンバー作成時に自由に決められる一意な文字列
 */
export function anonymousEmail(teamLoginId: string, loginId: string) {
  return `${loginId}.${teamLoginId}@example.com`;
}
